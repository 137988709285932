import React from 'react';
import styles from './styles/Banner.module.scss';
import LinkButton from './LinkButton';
import Lottie from './Lottie';

const Banner = ({
  btnLink,
  btnEventName,
  btnText,
  lottieName,
  imgSrc,
  disclaimer,
  title,
  subtitle,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.title}>
            <h1>{title}</h1>
            <p>{subtitle}</p>
          </div>
          <LinkButton
            href={btnLink}
            background="#54A702"
            className={styles.btn}
            eventName={btnEventName}
          >
            {btnText}
          </LinkButton>
        </div>

        <div className={styles.right}>
          {lottieName && <Lottie className={styles.lottie} name={lottieName} />}
          {imgSrc && <img alt="Banner image" src={imgSrc} />}
        </div>
      </div>
      {disclaimer && <div className={styles.disclaimer}>{disclaimer}</div>}
    </div>
  );
};
export default Banner;
