import React, { useMemo } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import classnames from 'classnames';
import { Link } from 'gatsby';
import Layout from '../../components/Layout';
import BackToTop from '../../components/BackToTop';
import SEO from '../../components/seo';
import { ConfusionPreFooterLight } from '../../components/PreFooter';
import styles from './styles/post.module.scss';
import { formatDateBlog, getBlogSections } from '../../common/utils';
import Img from 'gatsby-image';
import ContentfulLinks from '../../components/ContentfulLinks';
import Banner from '../../components/Banner';
import { GET_STARTED, MARKET_SCAN_ROUTE } from '../../constants/routes';
import MsGif from '../../animations/marketScan.gif';

const getPostDescription = ({ richText }) => {
  try {
    const content = richText.json.content || [];
    const desc = content.find((c) => c.nodeType === 'paragraph');
    return desc ? desc.content[0].value.trim() : undefined;
  } catch (ex) {
    console.error(ex);
    return undefined;
  }
};

const baseOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: ContentfulLinks,
  },
};

const ImgAsset = (node) => {
  const { file } = node.data.target.fields;
  const url = file['en-US'].url;
  return <img src={`https://${url}`} className={styles.img} />;
};

const ImgEntry = (node) => {
  const lang = 'en-US';
  const { asset, altText, description } = node.data.target.fields;
  const url = asset[lang].fields.file[lang].url;
  return (
    <div className={styles.detailedImage}>
      <img src={`https://${url}`} className={styles.img} alt={altText[lang]} />
      <span>{documentToReactComponents(description[lang], baseOptions)}</span>
    </div>
  );
};

const ImageAsset = ({ photo, detailedImage }) => {
  return detailedImage ? (
    <div className={styles.detailedImage}>
      <Img
        className={styles.img}
        fluid={detailedImage.asset.fluid}
        alt={detailedImage.altText}
      />
      <span>
        {documentToReactComponents(detailedImage.description.json, baseOptions)}
      </span>
    </div>
  ) : (
    <Img className={styles.img} fluid={photo.fluid} alt={photo.title} />
  );
};

const BlogTable = (node) => {
  return (
    <div className={styles.tableContainer}>
      {documentToReactComponents(node, baseOptions)}
    </div>
  );
};

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: ImgAsset,
    [BLOCKS.EMBEDDED_ENTRY]: ImgEntry,
    [BLOCKS.TABLE]: BlogTable,
    [INLINES.HYPERLINK]: ContentfulLinks,
  },
};

const PostBanner = ({ index, slug }) => {
  const utm = `/?utm_source=blog&utm_content=${slug.replace(/-/g, '')}`;
  const propsMap = {
    0: {
      btnLink: `${MARKET_SCAN_ROUTE}${utm}`,
      btnText: 'Try Market Scan - it’s free',
      lottieName: 'piggy',
      disclaimer:
        '*Household average savings is calculated assuming: 2 x Cars, 1 x House and 1 x Contents insurance policy. Average price difference and median price difference are calculated from data generated by Market Scan across thousands of quotes from 1 Jan 23 to 31 Mar 23',
      title: (
        <>
          The average Kiwi household can save
          <br /> $2,023.79 a year by shopping their insurance!
        </>
      ),
      subtitle:
        'Find out how your renewal prices stack up against other options out there!',
    },
    1: {
      btnLink: `${GET_STARTED}${utm}`,
      btnText: 'Get started for free',
      lottieName: 'savings',
      disclaimer:
        "We scan through 10+ insurance companies, though please note that this may not cover the entire market, as some insurers don't offer online quotes. Additionally, if you reside in high-risk areas like Christchurch, Wellington, etc., the options for house and contents insurance might be limited. Kindly note that we do not offer advice; our service solely provides online comparisons to aid you in finding insurance.",
      title: 'Shop smarter with Quashed',
      subtitle:
        'Insurance just got way easier with Quashed. Compare, shop and track all your insurance in one place.',
    },
    2: {
      btnLink: `${GET_STARTED}${utm}`,
      btnText: 'Get started for free',
      imgSrc: MsGif,
      title: 'Market Scan quotes are direct from insurers',
      subtitle: 'So you won’t pay more with Quashed',
    },
  };

  const props = propsMap[index];

  return props ? (
    <Banner btnEventName={`Blog-Banner-CTA-Click-${index + 1}`} {...props} />
  ) : null;
};

const NormalPost = ({
  controls,
  title,
  date,
  photo,
  richText,
  detailedImage,
}) => {
  return (
    <>
      <div className={styles.header}>
        <div className="container">
          <div className={styles.subHeader}>The Quashed Blog</div>
          <div className={styles.title}>{title}</div>
          <div className={styles.date}>{date}</div>
        </div>
      </div>
      <div className={classnames('container', styles.container)}>
        {controls}
        <div className={styles.content}>
          <div className={styles.contentHeader}>
            <ImageAsset photo={photo} detailedImage={detailedImage} />
          </div>
          <div className={styles.contentBody}>
            {documentToReactComponents(richText.json, options)}
          </div>
        </div>
        {controls}
      </div>
    </>
  );
};

const getLayoutClassName = (layout) => {
  switch (layout) {
    case 'left':
      return styles.leftSection;
    case 'right':
      return styles.rightSection;
    default:
      return '';
  }
};

const SectionedPost = ({
  controls,
  sections,
  photo,
  photoAlign,
  detailedImage,
  richText,
  title,
  subtitle,
  date,
  updatedDate,
  backToTopSections,
  bannerSections = [],
  slug,
}) => {
  const className = getLayoutClassName(photoAlign);
  const backToTop = backToTopSections || [];
  const banners = bannerSections || [];

  return (
    <div className={styles.sectionContainer}>
      {/* Header section */}
      <div className={classnames(styles.titleSection, 'clearfix', className)}>
        <div className="container">
          {controls}
          {photoAlign !== 'none' && (
            <ImageAsset photo={photo} detailedImage={detailedImage} />
          )}
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{subtitle}</div>
          <div className={styles.date}>
            {updatedDate ? `Updated ${formatDateBlog(updatedDate)}` : date}
          </div>
          {documentToReactComponents(richText.json, options)}
        </div>
      </div>
      {sections.map((section, idx) => {
        const cn = getLayoutClassName(section.layout);
        const isLast = idx === sections.length - 1;
        const showBanner = banners.includes(idx.toString());
        return (
          <div
            key={`Section${idx}`}
            id={`Section${idx}`}
            className={classnames(styles.section, 'clearfix', cn)}
          >
            <div className="container">
              {documentToReactComponents(section.json, options)}
              {showBanner && (
                <PostBanner
                  index={banners.findIndex((b) => b === idx.toString())}
                  slug={slug}
                />
              )}
              {(backToTop.includes(idx.toString()) ||
                (backToTop.length > 0 && isLast)) &&
                !showBanner && <BackToTop className={styles.backToTop} />}
              {isLast && controls}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const Post = ({ pageContext: { node, prevPost, nextPost, baseUrl } }) => {
  const {
    title,
    subtitle,
    date: postDate,
    updatedDate,
    richText,
    photo,
    photoAlign,
    detailedImage,
    slug,
    backToTopSections,
    bannerSections,
  } = node;

  const description = getPostDescription(node);
  const sections = getBlogSections(node);

  const date = formatDateBlog(postDate);

  const controls = useMemo(
    () => (
      <div className={styles.controls}>
        {prevPost && (
          <Link className={styles.left} to={prevPost}>
            <i className="fa-solid fa-chevron-left" aria-hidden="true"></i>
            <b>Previous</b>
          </Link>
        )}
        <Link className={styles.view} to="/blog">
          <b>
            <u>View all</u>
          </b>
        </Link>
        {nextPost && (
          <Link className={styles.right} to={nextPost}>
            <b>Next</b>
            <i className="fa-solid fa-chevron-right" aria-hidden="true"></i>
          </Link>
        )}
      </div>
    ),
    [prevPost, nextPost],
  );

  const fullTitle = subtitle ? `${title} ${subtitle}` : title;
  const ogTitle = `Quashed | ${fullTitle}`;

  const ogUrl = `/blog/${slug}`;
  const ogImage = `https:${photo.fluid.src}`;

  return (
    <Layout basePath={baseUrl} footerClassName={styles.footer}>
      <SEO
        description={description}
        title={fullTitle}
        ogTitle={ogTitle}
        ogUrl={ogUrl}
        ogImage={ogImage}
      />
      {sections.length > 0 ? (
        <SectionedPost
          controls={controls}
          title={title}
          subtitle={subtitle}
          date={date}
          updatedDate={updatedDate}
          photo={photo}
          richText={richText}
          sections={sections}
          photoAlign={photoAlign}
          detailedImage={detailedImage}
          backToTopSections={backToTopSections}
          bannerSections={bannerSections}
          slug={slug}
        />
      ) : (
        <NormalPost
          controls={controls}
          title={fullTitle}
          date={date}
          photo={photo}
          richText={richText}
          detailedImage={detailedImage}
        />
      )}
      <ConfusionPreFooterLight />
    </Layout>
  );
};

export default Post;
